.str-chat {
    height: 100%;
}

.str-chat__textarea textarea:focus {
    background: rgba(0, 0, 0, 0.7);
}

.str-chat-channel .str-chat__container {
    height: calc(100% - env(keyboard-inset-height, 17%));  /* TODO: use non-magic number here */
}